.employees-page {
  width: 100%;
  padding-bottom: 32px;

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 48px;
  }

  &__filters {
    margin-bottom: 32px;
  }

  &__filters-row {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    & > * {
      width: 200px;
    }
  }
}

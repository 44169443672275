@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

#root {
  display: flex;
  min-height: 100vh;
}

.MuiButtonBase-root {
  height: 40px;
  line-height: 1 !important;
}

.MuiIconButton-sizeSmall,
.MuiAutocomplete-clearIndicator,
.MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  height: 28px;
}

.MuiSwitch-root .MuiButtonBase-root {
  height: unset;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell,
.MuiDataGrid-columnHeaderCheckbox {
  outline: none !important;
}

.MuiAutocomplete-endAdornment {
  top: 0 !important;
}

.MuiChip-root {
  line-height: 1 !important;
}


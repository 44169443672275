.app-header {
  &__logo-wrap {
  }

  &__logo {
    width: 140px;
  }

  &__menu {
    display: flex;
    column-gap: 16px;
    margin-right: auto;
    margin-left: 64px;
  }

  &__link {
    * {
      background: transparent !important;
    }
  }
}
